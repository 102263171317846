const base = process.env.REACT_APP_BASE_URL;

const global_variables = () => {
  return {
    sign_in: `${base}:2000/api/v1/auth/login`,
    signup: `${base}:2000/api/v1/auth/signup`,
    checkuser: `${base}:2000/api/v1/auth/available`,
    resetemail: `${base}:2000/api/v1/auth/reset`,
    resetPwd: `${base}:2000/api/v1/auth/reset/verify`,

    //Course related
    getCourses: `${base}:1906/api/v1/courses`,
    getCategories: `${base}:1906/api/v1/categories`,
    getSections: `${base}:1906/api/v1/sections`,
    getReviews: `${base}:1908/api/v1/reviews`,
    postReviews: `${base}:1908/api/v1/reviews`,
    getUser: `${base}:1904/api/v1/users`,
    faq: `${base}:3031/faq`,
    quiz_questions: `${base}:3031/quiz/question`,
    quiz_answers: `${base}:3031/quiz/answers`,
    scores: `${base}:3031/quiz/scores`,
    results: `${base}:3031/quiz/quiz-results`,
    request_reseat: `${base}:3031/quiz/request-reseat`,
    files: `${base}:3002/additional-instructor/files`,

    //Cart
    getCart: `${base}:1911/api/v1/carts`,
    addToCart: `${base}:1911/api/v1/carts`,
    delFromCart: `${base}:1911/api/v1/carts`,

    //User profile
    updateProfile: `${base}:1904/api/v1/users`,
    updateinformation: `${base}:1901/api/v1/users`,

    //transactions
    getTransactions: `${base}:1098/transactions/list`,

    //notification
    getNotifications: `${base}:1099/notification/list`,
    updateSeen: `${base}:1099/notification/seen`,
    postNotification: `${base}:1099/notification/send-notification`,

    //myCourse
    get_my_paid_courses: `${base}:1098/transactions/listpaid`,
    updateSection: `${base}:3001/additional-course-service/sections/complete`, //user/course/section
    getSectionStatus: `${base}:3001/additional-course-service/sections`, //user/section
    getAllCompletedSections: `${base}:3001/additional-course-service/sections/all`, //user/course

    //transactions
    verifyTransactions: `${base}:1098/transactions/verify`,
    initiatePayment: `${base}:1098/transactions/initialize`,

    //messages
    sendMessage: `${base}:1913/api/v1/messages`,
    getContactList: `${base}:3000/messages/contact`,
    getRequestList: `${base}:3000/messages/request`,
    getMsgName: `${base}:3000/messages/name`,
    getMsg: `${base}:3000/messages/chats`,
    sendMsg: `${base}:3000/messages/send`,
    getMessages: `${base}:1913/api/v1/messages`,

    //certs
    getCerts: `${base}:3001/additional-course-service/certs`,

    //uploads
    upload: `${base}:1905/api/v1/uploads`,
    secondary_upload: `${base}:1101/upload`,

    //free course
    get_free_course: `${base}:3001/additional-course-service/free_courses`,

    // send email
    sendemail: `${base}:1100/send-email`,
    send_sms: `${base}:1916/send-sms`,

    /**
     * New message
     */

    chatlist: `${base}:9110/simple-chat/chats`,
    conversations: `${base}:9110/simple-chat/messages`,
    unread_conversation: `${base}:9110/simple-chat/unread`,

    preview: `${base}:3001/additional-course-service/course-preview`,
    record_plays: `${base}:3001/additional-course-service/course-view`,

    //Advertisements
    get_ads: `${base}:1919/advertisement/get-by-page`,
    interact_ads: `${base}:1919/advertisement/interactions`,
    site_configurations: `${base}:4040/site-configuration`,

    shared_certificates: `${base}:3031/quiz/certificate`,
  };
};

export default global_variables;
