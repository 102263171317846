import { useEffect, useState } from "react";
import logout_and_redirect from "../component/hoc/logout-redirect";
import { Services } from "../mixing/services";
import global_variables from "../mixing/urls";
import GeneralContext from "./general_context";
import storageBox from "../mixing/storage-box";

const GeneralContextProvider = (props) => {
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState([]);
  const [cartLoading, setCartLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [notifications, setNotification] = useState([]);
  const [notifLoading, setNotifLoadding] = useState(true);

  useEffect(() => {
    checkStatus();
    getCartData();
    getNotifications();
  }, []);

  const getNotifications = async () => {
    const user_data = storageBox.getUser();
    try {
      const res = await (
        await Services()
      ).get(global_variables().getNotifications + `/${user_data.user_id}`);

      // console.log(res.data?.payload);
      setNotification(res.data?.payload);
      setNotifLoadding(false);
    } catch (err) {
      setNotifLoadding(false);
    }
  };

  const checkStatus = async () => {
    try {
      const token = storageBox.getToken();
      if (token) {
        const user_data = storageBox.getUser();
        setIsLogged(true);
        setUser(user_data);
      } else {
        setIsLogged(false);
        storageBox.clearStorage();
      }

      // console.log(token);
    } catch (err) {
      setIsLogged(false);
    }
  };

  const getCartData = async () => {
    if (storageBox.getToken() !== null) {
      setCartLoading(true);
      const user_data = storageBox.getUser();
      try {
        const res = await (
          await Services()
        ).get(
          global_variables().getCart +
            `?user_id=${user_data.user_id}&query_fields=price,status,image,course_id,cart_id,description`
        );
        // console.log(res.data?.data?.data);
        setCart(res.data?.data);
        setCartLoading(false);
      } catch (err) {
        if (err.response?.status === 401) {
          logout_and_redirect();
        }
      }
    }
  };

  const [myCourses, setMyCourses] = useState([]);
  useEffect(() => {
    const get_my_courses = async () => {
      try {
        const res = await (
          await Services()
        ).get(
          global_variables().get_my_paid_courses +
            `/${storageBox.getUser()?.user_id}`
        );
        const my_courses = res.data?.payload?.map((d) => d.course_id);
        setMyCourses(my_courses);
      } catch (error) {}
    };
    if (storageBox.getToken()) {
      get_my_courses();
    }
  }, [user]);

  const [messageCounter, setMessageCounter] = useState(0);
  const savedToken = storageBox.getToken();
  useEffect(() => {
    const get_messages = async () => {
      if (savedToken) {
        const user_data = storageBox.getUser();
        try {
          const response = await (
            await Services()
          ).get(
            global_variables().unread_conversation + `/${user_data.user_id}`
          );
          // console.log("response from backend", response.data);
          setMessageCounter(response.data?.payload);
        } catch (error) {}
      }
    };

    setInterval(() => {
      get_messages();
    }, 120000);
    get_messages();
  }, [savedToken]);

  const [topics, setTopics] = useState([]);
  const getCategories = async () => {
    try {
      const res = await (
        await Services()
      ).get(global_variables().getCategories);
      setTopics(res.data?.data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <GeneralContext.Provider
      value={{
        isLogged,
        setIsLogged,
        user,
        cart,
        cartLoading,
        getCartData,
        notifications,
        setNotification,
        getNotifications,
        notifLoading,
        myCourses,
        messageCounter,
        topics,
      }}
    >
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralContextProvider;
