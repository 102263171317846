import CryptoJS from "crypto-js";

const storageBox = {
  storeToken: (token) => {
    const encryptedToken = CryptoJS.AES.encrypt(
      token,
      "encryptionSecretKey"
    ).toString();
    const expirationDate = new Date().getTime() + 10 * 60 * 60 * 1000; // 10 hours expiration
    const tokenData = {
      token: encryptedToken,
      expiration: expirationDate,
    };
    localStorage.setItem("encryptedToken", JSON.stringify(tokenData));
  },

  getToken: () => {
    const tokenDataString = localStorage.getItem("encryptedToken");
    if (tokenDataString) {
      const tokenData = JSON.parse(tokenDataString);
      //   console.log(tokenData, currentTime);
      const { token, expiration } = tokenData;
      const currentTime = new Date().getTime();
      if (expiration && currentTime < expiration) {
        const decryptedToken = CryptoJS.AES.decrypt(
          token,
          "encryptionSecretKey"
        ).toString(CryptoJS.enc.Utf8);
        return decryptedToken;
      }
    }
    return null;
  },

  storeUser: (user) => {
    const encryptedUser = CryptoJS.AES.encrypt(
      JSON.stringify(user),
      "encryptionSecretKey"
    ).toString();
    localStorage.setItem("encryptedUser", encryptedUser);
  },

  getUser: () => {
    const encryptedUser = localStorage.getItem("encryptedUser");
    if (encryptedUser) {
      const decryptedUser = CryptoJS.AES.decrypt(
        encryptedUser,
        "encryptionSecretKey"
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedUser);
    }
    return null;
  },
  clearStorage: () => {
    localStorage.removeItem("encryptedToken");
    localStorage.removeItem("encryptedUser");
    // window.location.href = "/login";
    console.log(`user was logged out`, window.location.pathname);
  },
};

export default storageBox;
